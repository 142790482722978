import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import VideoSection from '../VideoSection';
import VideoSectionRandom from '../VideoSectionRandom';
import { getOneSectionVideoById } from '../../store/actions/videoActions';
import OneVideoSkeleton from '../../shared/components/OneVideoSkeleton';
import HelmetOneVideo from './HelmetOneVideo';

import './video.css';


function OneVideo(props) {
    const dispatch = useDispatch();
    const video = useSelector(state => state.videoReducer.currentVideo);
    const { loading, error } = useSelector(state => state.videoReducer);

    const { videoId } = useParams();
    const { category } = useParams();

    useEffect(() => {
        if (!video) {
            dispatch(getOneSectionVideoById(category, videoId));
        }
    }, [dispatch, video, videoId, category]);


    return (
        <>
            {video && <HelmetOneVideo video={video} category={category} videoId={videoId} />}
            {video &&
                (<div>
                    <div className='one-video-layout'>
                        <div className='video-player'>
                            <iframe
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${video.videoId}?rel=0&autoplay=1`}
                                title={`${video.title}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                        </div>
                        <div className='video-player-info'>
                            <h2 className="video-player-video-title">{video.title}</h2>
                            <div className='flex-container flex-left'>
                                <div className="video-avatar"></div>
                                <h4 className='channel-name'> {video.channelName}</h4>
                            </div>
                            <p>{video.description}</p>
                        </div>
                        <div className='related-videos'>
                            <div className='special-one-video-block'>
                                <div id="yandex_rtb_R-A-1691059-39"></div>
                                <script>
                                    {window.yaContextCb.push(() => {
                                        window.Ya.Context.AdvManager.render({
                                            blockId: "R-A-1691059-39",
                                            renderTo: "yandex_rtb_R-A-1691059-39"
                                        })
                                    })}</script>
                            </div>

                         {/*    <div className='special-one-video-block'>
                                <div id="yandex_rtb_R-A-1691059-41"></div>
                                <script>
                                    {window.yaContextCb.push(() => {
                                        window.Ya.Context.AdvManager.render({
                                            blockId: "R-A-1691059-41",
                                            renderTo: "yandex_rtb_R-A-1691059-41"
                                        })
                                    })}</script>
                            </div> */}

                        </div>
                        <div className="full-width-section">
                            <VideoSection limitForShow={12} shouldShuffle={true} />
                            <VideoSectionRandom />
                        </div>
                    </div>
                </div>)
            }
            {loading && <OneVideoSkeleton />}
            {error && <h3>{error}</h3>}
        </>

    );
}

export default OneVideo;


