import React from "react";

const Idx1VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-26"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-26",
                        renderTo: "yandex_rtb_R-A-1691059-26"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx2VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-27"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-27",
                        renderTo: "yandex_rtb_R-A-1691059-27"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx5VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-28"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-28",
                        renderTo: "yandex_rtb_R-A-1691059-28"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx11VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-29"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-29",
                        renderTo: "yandex_rtb_R-A-1691059-29"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx14VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-30"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-30",
                        renderTo: "yandex_rtb_R-A-1691059-30"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx20VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-31"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-31",
                        renderTo: "yandex_rtb_R-A-1691059-31"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx33VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-32"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-32",
                        renderTo: "yandex_rtb_R-A-1691059-32"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx37VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-33"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-33",
                        renderTo: "yandex_rtb_R-A-1691059-33"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx56VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-34"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-34",
                        renderTo: "yandex_rtb_R-A-1691059-34"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx59VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-35"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-35",
                        renderTo: "yandex_rtb_R-A-1691059-35"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx70VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-36"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-36",
                        renderTo: "yandex_rtb_R-A-1691059-36"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx82VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-37"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-37",
                        renderTo: "yandex_rtb_R-A-1691059-37"
                    })
                })}</script>
        </React.Fragment>
    )
}

const Idx93VideoSectionRandom = () => {
    return (
        <React.Fragment>
            <div className="video-section-random" id="yandex_rtb_R-A-1691059-38"></div>
            <script>
                {window.yaContextCb.push(() => {
                    window.Ya.Context.AdvManager.render({
                        blockId: "R-A-1691059-38",
                        renderTo: "yandex_rtb_R-A-1691059-38"
                    })
                })}</script>
        </React.Fragment>
    )
}



const adsObjVideoSectionRandom = {
    1: <Idx1VideoSectionRandom />,
    2: <Idx2VideoSectionRandom />,
    5: <Idx5VideoSectionRandom />,
    11: <Idx11VideoSectionRandom />,
    14: <Idx14VideoSectionRandom />,
    20: <Idx20VideoSectionRandom />,
    33: <Idx33VideoSectionRandom />,
    37: <Idx37VideoSectionRandom />,
    56: <Idx56VideoSectionRandom />,
    59: <Idx59VideoSectionRandom />,
    70: <Idx70VideoSectionRandom />,
    82: <Idx82VideoSectionRandom />,
    93: <Idx93VideoSectionRandom />,
}

export default adsObjVideoSectionRandom;
